<template>
  <v-container fluid>
    <v-row v-if="sites !== null">
      <template v-for="site in sites">
        <v-col
          :key="site.site_name"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <SiteCard :site="site" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>

  import SiteCard from "./SiteCard"

  export default {
    name: 'SiteGrid',
    components: {
      SiteCard
    },
    props: {
      sites: {
        type: Array, 
        default: () => []
      }
    },
    data: () => ({
    }),
  }
</script>

<style>
  .dense-border {
    border: 2px #999 dashed;
  }

  .box {
    display: block;
    width: 100%;
  }

  .dense-border:hover {
    cursor: pointer;
    background: #fff;
  }

</style>