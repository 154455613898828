<template>
  <v-card>
    <v-img
      :src="'https://irp-cdn.multiscreensite.com/' + site.site_name + '/screenshots/Screenshot.png'"
    />
    <v-card-title>{{ site.site_name }}</v-card-title>
    <v-card-actions>
      <v-btn
        disabled
        text
        color="red"
      >
        {{ $t('delete') }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        dark
        @click="editSite"
        :loading="processingEdit"
        :disabled="processingEdit"
      >
        {{ $t('edit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import axios from 'axios'

  export default {
    name: 'SiteCard',
    props: {
      site: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({
      processingEdit: false
    }),
    methods: {
      editSite() {
        this.processingEdit = true
        const query = '{ ssoSitesLink(site_name: "' + this.site.site_name + '") }'

        axios({
          url: this.$store.state.graphUrl,
          method: 'post',
          data: {
            query: query
          }
        }).then(response => {

          if(response.data.ssoSitesLink !== null) {
            document.location = response.data.data.ssoSitesLink
          }

        }).finally(() => {
          this.processingEdit = false
        })
      }
    }
  }
</script>

<style>
  .iframe-wrapper {
    transform: scale(0.5,0.5);
    width: 200%;
    height: 200px;
    transform-origin: top left;
  }

  iframe {
    display: inline-block;
    width: 100%;
    height: 360px;
    border: 0;
  }
</style>


